import * as React from 'react';

import Section from "@components/Section";
import Paginator from "@components/Navigation/Navigation.Paginator";

import ArticlesHero from "../@narative/gatsby-theme-novela/sections/articles/Articles.Hero";
import ArticlesList from "../@narative/gatsby-theme-novela/sections/articles/Articles.List";
import Layout from "../@narative/gatsby-theme-novela/components/Layout/Layout";
import styled from "styled-components";
import {graphql} from "gatsby";

const articles = graphql`
    query Articles {
        allContentfulPost {
            edges {
                node {
                    id
                    title
                    date
                    excerpt
                    author {
                        id
                    }
                }
            }
        }
    }
`

const authors = graphql`
    query Authors {
        allContentfulAuthor {
            edges {
                node {
                    id
                    name
                    featured
                }
            }
        }
    }
`

// BUG: Requires prop types <pageContext, location>
// Ref <ArticlesList> implements T<IArticle[]>
// Passed custom query would need to conform with <T>
// Ref data.query.js in node_modules for Type Providers
class Insights extends React.Component {
    public componentDidMount() {
        console.log('AuthorQuery', authors);
        console.log('ArticlesQuery', articles);
    }

    public render() {
        // Copied from articles.template
        return (
            <>
                {/*<Layout>*/}
                {/*    <ArticlesHero authors={authors}/>*/}
                {/*    <Section narrow>*/}
                {/*        <ArticlesList articles={articles}/>*/}
                {/*        /!*<ArticlesPaginator show={pageContext.pageCount > 1}>*!/*/}
                {/*        /!*    <Paginator {...pageContext} />*!/*/}
                {/*        /!*</ArticlesPaginator>*!/*/}
                {/*    </Section>*/}
                {/*</Layout>*/}
            </>
        )
    }
}

export default Insights;

const ArticlesPaginator = styled.div<{ show: boolean }>`
  ${p => p.show && `margin-top: 95px;`}
`;